import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getScheduling({
  eventId,
  offset,
  limit,
  search,
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/temp/agendamentosbyevent/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export { getScheduling };
