import axios from "axios";
import { urlBase } from "./config";

export async function createStockActivity(
  eventId,
  dataHoraInicio,
  dataHoraFim,
  quantidadeMax,
  quantidadeUtilizada,
  atividade,
  sala,
  token
) {
  try {
    const response = await axios.post(
      `${urlBase}/estoqueatividades`,
      {
        eventId,
        dataHoraInicio,
        dataHoraFim,
        quantidadeMax,
        quantidadeUtilizada,
        atividade,
        sala,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao enviar atividade de estoque:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao criar a atividade de estoque."
    );
  }
}

export async function updateStockActivity(id, quantidadeMax, token) {
  try {
    const response = await axios.put(
      `${urlBase}/estoqueatividades/${id}`,
      {
        quantidadeMax,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar a atividade de estoque:", error);
    throw new Error(
      error.response?.data?.message ||
        "Erro ao atualizar a atividade de estoque."
    );
  }
}

export async function deleteStockActivity(id, token) {
  try {
    const response = await axios.delete(`${urlBase}/estoqueatividades/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao deletar a atividade:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao deletar a atividade."
    );
  }
}

export async function getStockActivities(
  eventId,
  token,
  offset,
  limit,
  search,
  status,
  columnSearch
) {
  try {
    const response = await axios.get(
      `${urlBase}/estoqueatividades/filters/event/${eventId}?offset=${offset}&limit=${limit}&search=${search}&status=${status}&columnSearch=${columnSearch}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar atividades de estoque:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao obter atividades de estoque."
    );
  }
}
