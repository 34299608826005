import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
// moment
import moment from "moment";
// service
import { getSalesDetails } from "../../../utils/services/sales";

export default function ModalDetails(props) {
  const { isOpen, toggle, idSelected, token } = props;
  // carregar
  const [loading, setLoading] = useState(true);
  //   dados
  const [data, setData] = useState([]);

  function getTypePayment(type) {
    switch (type) {
      case "pix":
        return "Pix";

      case "credit_card":
        return "Cartão de Crédito";

      case "free":
        return "Gratuito";

      default:
        return "";
    }
  }

  function getStatusPayment(status) {
    switch (status) {
      case "pending":
        return "Pendente";

      case "paid":
        return "Pago";

      case "canceled":
        return "Cancelado";

      case "refunded":
        return "Reembolsado";

      default:
        return "";
    }
  }

  function getInfo() {
    getSalesDetails({ jwt: token, idSale: idSelected })
      .then((res) => {
        if (res.transactions[0]) setData(res.transactions);

        setLoading(false);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getInfo();
  }, []);

  if (loading)
    return (
      <>
        <Modal isOpen={isOpen} centered>
          <ModalHeader toggle={toggle}>Detalhes da venda</ModalHeader>
          <ModalBody style={{ display: "flex", justifyContent: "center" }}>
            <div className="circle-loading" />
          </ModalBody>
        </Modal>
      </>
    );

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Detalhes da venda</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Produto</a>
                <a style={{ textAlign: "end" }}>{data[0]?.description}</a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Quantidade</a>
                <a style={{ textAlign: "end" }}>{data[0]?.quantity}</a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Código promocional</a>
                <a style={{ textAlign: "end" }}>
                  {data[0]?.promo_code === "NOCODE"
                    ? "Não aplicado"
                    : data[0]?.promo_code}
                </a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Valor Total</a>
                <a style={{ textAlign: "end" }}>R$ {data[0]?.amount}</a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Forma de pagamento</a>
                <a style={{ textAlign: "end" }}>
                  {getTypePayment(data[0]?.payment_type)}
                </a>
              </div>
            </Col>
          </Row>

          {data[0]?.payment_type === "credit_card" && (
            <Row>
              <Col lg={12}>
                <div className="line-underline">
                  <a>Quantidade de parcelas</a>
                  <a style={{ textAlign: "end" }}>{data[0].installments} x</a>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Status da compra</a>
                <a style={{ textAlign: "end" }}>
                  {getStatusPayment(data[0]?.payment_status)}
                </a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Data da compra</a>
                <a style={{ textAlign: "end" }}>
                  {moment(data[0]?.criado_em).format("DD/MM/YY")} -{" "}
                  {moment(data[0]?.criado_em).format("LT")}
                </a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="line-underline">
                <a>Nomes</a>
                <a style={{ textAlign: "end" }}>
                  {data
                    .map((e) => {
                      return `${e.nome} (${e.email})`;
                    })
                    .join(", ")}
                </a>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
