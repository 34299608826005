import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import moment from "moment";
import { updateStockActivity } from "../../../utils/services/schedulingAndStock";

export default function ModalEditStock(props) {
  const { isOpen, toggle, data, jwt, refetch } = props;

  // Carregamento
  const [loading, setLoading] = useState(false);

  const formatDateToInput = (date) => {
    return moment(date).add(3, "hours").format("YYYY-MM-DDTHH:mm");
  };

  const [formEdit, setFormEdit] = useState({
    room: data.sala,
    activity: data.atividade,
    initialDate: formatDateToInput(data.dataHoraInicio),
    endDate: formatDateToInput(data.dataHoraFim),
    maxQuantity: data.quantidadeMax,
    quantityUsed: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateStockActivity(data.id, Number(formEdit.maxQuantity), jwt);
      toggle();

      refetch();
    } catch (error) {
      console.error("Erro ao atualizar atividade de estoque:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Editar Estoque</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">ID</label>
                  <input
                    className="form-control"
                    type="text"
                    value={data.id}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Sala*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="room"
                    disabled
                    value={formEdit.room}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Atividade*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="activity"
                    disabled
                    value={formEdit.activity}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Data e Hora Inicio*</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="initialDate"
                    disabled
                    value={formEdit.initialDate}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Data e Hora Fim*</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="endDate"
                    disabled
                    value={formEdit.endDate}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Quantidade Máxima*</label>
                  <input
                    className="form-control"
                    type="number"
                    name="maxQuantity"
                    value={formEdit.maxQuantity}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <ButtonSpinner isLoading={loading} title={"Salvar"} />
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
