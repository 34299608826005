import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Row,
  Button,
  Collapse,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import ModalNewStock from "./ModalNewStock";
import ModalEditStock from "./ModalEditStock";
import ModalConfig from "./ModalConfig";
import ModalImportStock from "./ModalImportStock";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteStockActivity,
  getStockActivities,
} from "../../../utils/services/schedulingAndStock";
import moment from "moment";
import Pagination from "../../../components/Paginationv3";
import SweetAlert from "react-bootstrap-sweetalert";
import { createStockXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";

function Stock(props) {
  const location = useLocation();
  const token = props.state.global_user_data.data.token;

  const [search, setSearch] = useState("");
  const [activities, setActivities] = useState([]);
  const [activitiesEdiit, setActivitiesEdit] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalNewStock, setModalNewStock] = useState(false);
  const [modalEditStock, setModalEditStock] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [modalImportStock, setModalImportStock] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("sala");

  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const columnsFilter = [
    { name: "Sala", value: "sala", type: "text" },
    { name: "Atividade", value: "atividade", type: "text" },
    { name: "Data e Hora Inicio", value: "Data e Hora Inicio", type: "text" },
    { name: "Data e Hora Fim", value: "Data e Hora Fim", type: "text" },
    { name: "Quantidade Máxima", value: "Quantidade Máxima", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  const fetchStockActivities = async () => {
    setLoading(true);
    setError(null);

    try {
      const activitiesData = await getStockActivities(
        location.state.idEvent,
        token,
        currentIndex > 0 ? currentIndex : 1,
        itemsPerPage,
        search,
        activeFilter,
        "ATIVO"
      );
      setActivities(activitiesData);
      setCountData(activitiesData.total);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStockActivities();
  }, [
    location.state.idEvent,
    token,
    search,
    currentIndex,
    itemsPerPage,
    activeFilter,
  ]);

  useEffect(() => {
    console.log(activities?.data?.length);
  }, [activities]);

  return (
    <>
      {modalNewStock && (
        <ModalNewStock
          isOpen={modalNewStock}
          toggle={() => setModalNewStock(false)}
          idEvent={location.state.idEvent}
          jwt={token}
          refetch={fetchStockActivities}
        />
      )}

      {modalEditStock && (
        <ModalEditStock
          isOpen={modalEditStock}
          toggle={() => setModalEditStock(false)}
          data={activitiesEdiit}
          jwt={token}
          refetch={fetchStockActivities}
        />
      )}

      {modalConfig && (
        <ModalConfig
          isOpen={modalConfig}
          toggle={() => setModalConfig(!modalConfig)}
          idEvent={location.state.idEvent}
          jwt={token}
        />
      )}

      {confirmDelete && (
        <SweetAlert
          title={`Deseja deletar essa atividade?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteStockActivity(activitiesEdiit.id, token);

              await fetchStockActivities();
              setConfirmDelete(false);
            } catch (error) {
              console.error("Erro ao deletar a atividade:", error);
            }
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          {/* Você não poderá reverter essa ação. */}
          <a></a>
        </SweetAlert>
      )}

      {modalImportStock && (
        <ModalImportStock
          isOpen={modalImportStock}
          toggle={() => setModalImportStock(!modalImportStock)}
          idEvent={location.state.idEvent}
          jwt={token}
        />
      )}

      <WaitExport
        isOpen={exportInProgress}
        name={"Estoque"}
        preposicao="das"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <Card>
        <CardBody>
          <CardTitle>Estoque</CardTitle>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <div className="groups-match">
              <div className="group-btn-match header-btns-container">
                <Button
                  color="warning"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setModalConfig(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                    className="dripicons-gear"
                  />
                  <a style={{ display: "flex", alignItems: "center" }}>
                    Configurações
                  </a>
                </Button>
              </div>
            </div>
          </div>

          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="header-btns-container">
              {activities?.data?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => {
                    createStockXlsx({
                      eventId: location.state.idEvent,
                      token,
                      limit: 100,
                      countData,
                      search,
                      columnSearch: activeFilter,
                      setFinishExport,
                    });
                    setExportInProgress(true);
                  }}
                >
                  Exportar Relatório
                </Button>
              )}

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => setModalImportStock(true)}
              >
                Importar Estoque
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => setModalNewStock(true)}
              >
                Adicionar Estoque
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Sala</th>
                  <th>Atividade</th>
                  <th>Data e Hora Inicio</th>
                  <th>Data e Hora Fim</th>
                  <th>Quantidade Máxima</th>
                  <th>Quantidade Utilizada</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7">Carregando...</td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan="7">Erro: {error}</td>
                  </tr>
                ) : activities.data && activities.data.length > 0 ? (
                  activities.data.map((activity, index) => (
                    <tr key={index}>
                      <th>{activity.sala}</th>
                      <td>{activity.atividade}</td>
                      <td>
                        {moment
                          .utc(activity.dataHoraInicio)
                          .format("DD [de] MMM. [às] HH:mm")}
                      </td>
                      <td>
                        {moment
                          .utc(activity.dataHoraFim)
                          .format("DD [de] MMM. [às] HH:mm")}
                      </td>
                      <td>{activity.quantidadeMax}</td>
                      <td>{activity.quantidadeUtilizada}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModalEditStock(true);
                            setActivitiesEdit(activity);
                          }}
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setConfirmDelete(true);
                            setActivitiesEdit(activity);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">Nenhum item encontrado.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Pagination
        data={activities.data}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Stock);
