import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Col, Row, Button } from "reactstrap";

// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componente de cot
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

export default function ModalConfig(props) {
  const { isOpen, toggle, eventId, jwt } = props;

  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [successUpdateAlert, setSuccessUpdateAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [colorSchedules, setColorSchedules] = useState({
    backgroundColor: "#FFF",
    buttonColor: "#FFF",
  });

  const [rules, setRules] = useState({
    onePerRoom: false,
    onePerDate: false,
    noLimit: false,
    limitByDate: false,
    allowCancel: false,
  });

  const [dateTime, setDateTime] = useState({
    startDateTime: "",
    endDateTime: "",
  });

  const handleCheckboxChange = (e) => {
    const { name } = e.target;

    setRules((prevRules) => ({
      ...prevRules,
      onePerRoom: name === "onePerRoom" ? !prevRules.onePerRoom : false,
      onePerDate: name === "onePerDate" ? !prevRules.onePerDate : false,
      noLimit: name === "noLimit" ? !prevRules.noLimit : false,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setRules((prevRules) => ({
      ...prevRules,
      [name]: value === "sim",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        {successCreateAlert && (
          <SweetAlert
            success
            title={"Criado"}
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
          >
            Configurações criadas com sucesso
          </SweetAlert>
        )}

        {successUpdateAlert && (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              setSuccessUpdateAlert(false);
              toggle();
            }}
          >
            Configurações editadas com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Configurações do Agendamento</ModalHeader>

        <ModalBody>
          <Row>
            <Col xs="6" style={{ marginBottom: "10px" }}>
              <ColorPicker
                defaultColor={"#9DD212"}
                classOfContainer="primary-card-pickr"
                setStateFunc={(color) =>
                  setColorSchedules({
                    ...colorSchedules,
                    backgroundColor: color,
                  })
                }
                labelText="Cor de Fundo"
                tip="Cor que será utilizada no background do agendamento."
                idDiv="corPrimaria"
              />
            </Col>

            <Col xs="12" style={{ marginBottom: "20px" }}>
              <ColorPicker
                defaultColor={"#0E2CCB"}
                classOfContainer="secundary-card-pickr"
                setStateFunc={(color) =>
                  setColorSchedules({
                    ...colorSchedules,
                    buttonColor: color,
                  })
                }
                labelText="Cor dos Botões"
                tip="Cor que será utilizada nos botões do agendamento."
                idDiv="corSecundaria"
              />
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <label className="form-label">Regra:</label>
            </Col>

            <Col xs="12">
              <input
                type="checkbox"
                name="onePerRoom"
                className="form-check-input"
                checked={rules.onePerRoom}
                onChange={handleCheckboxChange}
                id="onePerRoom"
              />
              <label
                className="form-label text-sm"
                style={{ marginLeft: 10 }}
                htmlFor="onePerRoom"
              >
                Permitir apenas 1 agendamento por sala
              </label>
            </Col>

            <Col xs="12">
              <input
                type="checkbox"
                name="onePerDate"
                className="form-check-input"
                checked={rules.onePerDate}
                onChange={handleCheckboxChange}
                id="onePerDate"
              />
              <label
                className="form-label text-sm"
                style={{ marginLeft: 10 }}
                htmlFor="onePerDate"
              >
                Permitir apenas 1 agendamento por data
              </label>
            </Col>

            <Col xs="12">
              <input
                type="checkbox"
                name="noLimit"
                className="form-check-input"
                checked={rules.noLimit}
                onChange={handleCheckboxChange}
                id="noLimit"
              />
              <label
                className="form-label text-sm"
                style={{ marginLeft: 10 }}
                htmlFor="noLimit"
              >
                Não limitar os agendamentos
              </label>
            </Col>

            <Col xs="12" style={{ marginTop: "10px" }}>
              <label className="form-label">
                Limitar Agendamento por Data?
              </label>
              <select
                className="form-control"
                name="limitByDate"
                value={rules.limitByDate ? "sim" : "nao"}
                onChange={handleSelectChange}
              >
                <option value="nao">Não</option>
                <option value="sim">Sim</option>
              </select>
            </Col>

            {rules.limitByDate && (
              <>
                <Col md={6} style={{ marginTop: "10px" }}>
                  <label className="form-label">Data e Hora Início</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="startDateTime"
                    value={dateTime.startDateTime}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md={6} style={{ marginTop: "10px" }}>
                  <label className="form-label">Data e Hora Fim</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="endDateTime"
                    value={dateTime.endDateTime}
                    onChange={handleInputChange}
                  />
                </Col>
              </>
            )}

            <Col xs="12" style={{ marginTop: "10px" }}>
              <label className="form-label">
                Permitir que o participante exclua o seu agendamento?
              </label>
              <select
                className="form-control"
                name="allowCancel"
                value={rules.allowCancel ? "sim" : "nao"}
                onChange={handleSelectChange}
              >
                <option value="nao">Não</option>
                <option value="sim">Sim</option>
              </select>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              onClick={() => console.log("Salvo!")}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
